import React from 'react'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'

import { SEO, Layout } from 'Components'

import './projects.css'

export const ProjectsQuery = graphql`
  query {
    projects: allContentfulProject(sort: { fields: [featured, specs___startOfConstruction], order: DESC }) {
      totalCount
      edges {
        node {
          id
          slug
          name
          mainImage {
            title
            description
            fluid(maxWidth: 1200, quality: 85) {
              ...GatsbyContentfulFluid
              sizes
              srcSet
              sizes
              src
              srcSetWebp
            }
          }
          specs {
            program
            client
            programType
            author
          }
          orientation
          featured
        }
      }
    }
  }
`

const Projects = ({ location, data }) => {
  const { projects } = data
  const { edges } = projects

  return (
    <>
      <SEO pathname={location.pathname} title="Projects" description="Projects Gallery" />

      <Layout>
        <div className="flex justify-center flex-wrap">
          {edges.map(({ node }) => {
            const { orientation, featured } = node
            return (
              <article
                key={node.id}
                orientation={orientation}
                className={`thumbnail ${featured ? 'thumbnail-featured' : ''} self-center p-4 xl:p-12`}>
                <figure className="thumbnail-container">
                  <Link className="thumbnail-holder block" to={`/projects/${node.slug}`}>
                    <Img
                      key={node.id}
                      title={node.mainImage.title}
                      alt={node.mainImage.title}
                      description={node.mainImage.description}
                      fluid={node.mainImage.fluid}
                    />
                  </Link>
                  <figcaption className="thumbnail-info mb-4 text-lg">
                    <Link to={`/projects/${node.slug}`} className="thumbnail-link">
                      {node.name}
                    </Link>
                    <p className="text-sm text-gray-400">{node.specs.author}</p>
                  </figcaption>
                </figure>
              </article>
            )
          })}
        </div>
      </Layout>
    </>
  )
}

export default Projects
